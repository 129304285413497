.bio-info {
    text-align: center;
    color: #f9f8f9;
    animation: fadeIn 1.5s ease-in-out, slideUp 1.5s ease-in-out;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background: rgba(34, 34, 34, 0.8);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 3px solid #78ce23;
}

.bio-info h1 {
    margin-bottom: 10px;
    font-size: 2em;
    letter-spacing: 1px;
}

.bio-info p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 20px;
}

.social-links a {
    margin: 0 10px;
    color: #78ce23;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-links a:hover {
    color: #69b91f;
    transform: scale(1.1);
}

.social-links svg {
    margin-right: 8px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
    }
    to {
        transform: translateY(0);
    }
}