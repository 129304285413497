/* Genel ayarlar */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1a;
  color: #f0f0f0;
  overflow: hidden; /* Sayfanın taşmasını engeller */
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.App {
  min-height: 100vh;
  background-color: #222222;
  color: #aeaeae;
  font-size: calc(10px + 1vmin);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-body {
  background-color: black;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-header {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 20px;
}

.container-fluid {
  padding: 20px; /* İçerik için padding ekler */
}

.logo {
  margin-top: 300px;
  width: 500px;
  height: auto;

}

.action {
  color: #ff4d00;
}

.networkSelectorButton {
  text-align: center;
  margin-bottom: 20px;
}

.sats-connect-wallet-provider-selector {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* Bu değer, sayfanın geri kalanından daha yüksek olmalıdır */
}

.card {
  background-color: white;
  color: #282c34;

  padding: 0 20px 20px 20px;
  margin-bottom: 30px;
  border-radius: 20px;
}

.success {
  color: green;
}